import { initializeApp } from "firebase/app";
import { getFirestore, writeBatch } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
    apiKey: "AIzaSyCOOel2MC1dkFawB8C3XNwS8D7D5ArZJ4k",
    authDomain: "scary-teacher-integrity.firebaseapp.com",
    projectId: "scary-teacher-integrity",
    storageBucket: "sacry-teacher-integrity.appspot.com",
    messagingSenderId: "262296020845",
    appId: "1:262296020845:web:230f9d3fc080e466ae1c64",
    measurementId: "G-YM5QZF46ZD"
};

// const firebaseConfig = {
//     apiKey: "AIzaSyDyqxu4PvSYNDPQn-DSvEdTsqlKRFY4JqM",
//     authDomain: "geni-team-dashboard-45006.firebaseapp.com",
//     projectId: "geni-team-dashboard-45006",
//     storageBucket: "geni-team-dashboard-45006.appspot.com",
//     messagingSenderId: "64227190376",
//     appId: "1:64227190376:web:73a9c410da38f3a6843ddd",
//     measurementId: "G-XC96DV86XM"
//   };

// const firebaseConfig = {
//     apiKey: "AIzaSyDp_oXIj1EbFfeFy9whJDvnmMd6tnm6pRE",
//     authDomain: "crashlytics-admin.firebaseapp.com",
//     projectId: "crashlytics-admin",
//     storageBucket: "crashlytics-admin.appspot.com",
//     messagingSenderId: "789577929656",
//     appId: "1:789577929656:web:efef1487843507e4f3754d"
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
// const admin= firebase.firestore();
const auth = getAuth(app);
// const set =setDoc(app)

export { db, storage, auth }