import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col
} from "reactstrap";
import React, {useEffect, useState} from "react";
import {auth} from "../../firebase";
import {signInWithEmailAndPassword} from "firebase/auth";
import { ToastContainer, toast } from 'react-toastify';

const Login = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(null);

    useEffect(() => {
        console.log(props);
    }, [])

    const loginUser = async (e) => {
        e.preventDefault();
        setLoading("pending");
        try {
            const isLoggedIn = await signInWithEmailAndPassword(auth, email, password);
            toast.success('Login Success', {
                position: "top-right",
                autoClose: 2000,
                // hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                // progress: undefined,
                theme: "light",
            });
            setLoading(null);
            console.log(isLoggedIn);
        } catch (error) {
            setLoading(null);
            console.log(error.message);
            const message = error.message;
            const startIndex = message.indexOf("auth/") + 5; // adding 5 to exclude "auth/"
            const endIndex = message.length - 2;
            const errorMessage = message.substring(startIndex, endIndex);
            toast.error(errorMessage, {
                position: "top-right",
                autoClose: 2000,
                // hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                // progress: undefined,
                theme: "light",
            });
        }
    }

    return (
        <>
            <Col lg="5" md="7">
                <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                        <div className="text-center text-muted mb-4">
                            <small>Sign in with credentials</small>
                        </div>
                        <Form role="form" onSubmit={loginUser}>
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-email-83"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Email"
                                        type="email"
                                        autoComplete="new-email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        id="email"
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-lock-circle-open"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Password"
                                        type="password"
                                        autoComplete="new-password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <div className="text-center">
                                <Button className="my-2" color="primary" type="submit">
                                    {loading === "pending" ? (
                                    <div className="spinner-border spinner-border-sm" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    ) : (
                                    <span className="fs-6">Sign in</span>
                                    )}
                                </Button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </>
    );
};

export default Login;
