import React, {useEffect, useState} from 'react';
import Header from "../../components/Headers/Header";
import {
    Button,
    Card, CardBody,
    CardHeader,
    Col, Container,
    Form,
    FormGroup, Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Label, Modal, ModalBody, ModalHeader,
    Row
} from "reactstrap";
import Select from "react-select";
import {db} from "../../firebase";
import {collection, getDocs, addDoc, updateDoc, doc, deleteDoc} from "firebase/firestore";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";
import moment from "moment";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";


const AddGame = () => {
    const [selectedStudio, setSelectedStudio] = useState("");
    const [studioNames, setStudioNames] = useState([]);
    const [studioList, setStudioList] = useState([]);
    const [name, setName] = useState("");
    const [key, setKey] = useState("");
    const [loading, setLoading] = useState("loadingTable");
    const [games, setGames] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [editGameObject, setEditGameObject] = useState({});
    const [gameNames, setGameNames] = useState([]);
    const history = useHistory();

    useEffect(() => {
        loadGames().then(result => {
            console.log(result);
            setGames(result);
        })
        loadStudios().then((result) => {
            console.log(result);
            let studioNames = [];
            let studioBO = [];
            result.forEach((_item) => {
                studioNames.push(_item.name);
                studioBO[_item.name] = _item
            })
            const options = studioNames.map(name => ({value: name, label: name}));
            console.log(options);
            setStudioList(studioBO);
            setStudioNames(options);
            setLoading("ready");
        })
    }, []);
    useEffect(()=> {
        const _gamesNames = games.map(_game => _game.name);
        setGameNames(_gamesNames);
    },[games])

    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            width: "20%"
        },
        {
            name: 'Version',
            selector: row => row.version,
            center: true
        },
        {
            name: 'Studio Name',
            selector: row => row.studioName,
            width: "10%",
            center: true
        },
        {
            name: 'Key',
            selector: row => row.key,
            center: true
        },
        {
            name: 'Actions',
            cell: (row, i) => (
                <>
                    <Button size="sm" color="danger" onClick={() => deleteGame(row)}>
                        <i className="fas fa-trash"/>
                    </Button>
                    <Button size="sm" color="success" onClick={() => editGame(row)}>
                        <i className="fas fa-pencil-alt"/>
                    </Button>
                </>
            ),
            center: true,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const loadGames = async () => {
        const gamesCollection = collection(db, 'reporting_games');
        const gamesSnapshot = await getDocs(gamesCollection);
        return gamesSnapshot.docs.map(doc => {
            return {
                ...doc.data(), id: doc.id
            }
        });
    }
    const loadStudios = async () => {
        const studiosCollection = collection(db, 'reporting_studios');
        const studiosSnapshot = await getDocs(studiosCollection);
        return studiosSnapshot.docs.map(doc => doc.data());
    }
    const addNewGame = async () => {
        console.log(name, key, selectedStudio.value);
        setLoading("adding");
        try {
            if(gameNames.includes(name)){
                toast.error('Game Already Added', {
                    position: "top-right",
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                });
                setLoading(null);
                return;
            }
            let newGameObject = {
                key: key,
                name: name,
                studioName: selectedStudio.value,
                version: "1.0"
            };
            const docRef = await addDoc(collection(db, 'reporting_games'), newGameObject);
            console.log("Document written with ID: ", docRef.id);
            newGameObject = {...newGameObject, id : docRef.id};
            toast.success('Game Added', {
                position: "top-right",
                autoClose: 2000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
            });
            setName("");
            setKey("");
            setSelectedStudio("");
            setLoading(null);
            let newGames = games;
            newGames = [...newGames, newGameObject];
            setGames(newGames);
            toggleModal();
            // history.push("/");
        } catch (error) {
            console.error("Error adding document: ", error);
            setLoading(null);
        }
    }
    const toggleModal = () => {
        setName("");
        setKey("");
        setSelectedStudio("");
        setOpenModal(!openModal);
    }
    const toggleEditModal = () => {
        setEditModal(!editModal);
    }
    const deleteGame = async (row) => {
        console.log(row);
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result)=> {
            try{
                if (result.isConfirmed){
                    await deleteDoc(doc(db, "reporting_games", row.id));
                    const newGames = games.filter(game => game.id !== row.id);
                    setGames(newGames);
                }
            }catch (e) {
                console.log(e)
            }
        })
    }
    const editGame = async (row) => {
        setEditGameObject({...row, studioName: {value: row.studioName, label: row.studioName}});
        toggleEditModal();
    }
    const saveEdit = async () => {
        setLoading("editing");
        try {
            const docID = editGameObject.id;
            const docRef = doc(db, "reporting_games", docID);
            await updateDoc(docRef,{
                name : editGameObject.name,
                key : editGameObject.key,
                studioName : editGameObject.studioName.value,
                version : editGameObject.version
            });
            const originalArray = [...games];
            const index = originalArray.findIndex(obj => obj.id === docID);
            originalArray[index] = {...editGameObject, studioName: editGameObject.studioName.value};
            setGames(originalArray);
            setLoading("ready");
            toast.success('Game Updated', {
                position: "top-right",
                autoClose: 2000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
            });
            toggleEditModal();
        }catch (e) {
            console.log(e);
            setLoading("error");
        }
    }


    return (
        <div>
            <Header/>
            <Container className="mt--9" fluid>
                <Row className="mb-4">
                    <Col>
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h2 className="mb-0">Games</h2>
                                    <Button color="primary" onClick={toggleModal}>Add Game</Button>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <DataTable
                                    columns={columns}
                                    data={games}
                                    progressPending={loading === "loadingTable"}
                                    pagination
                                    responsive
                                    persistTableHead
                                    highlightOnHover
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal isOpen={openModal} toggle={toggleModal} className="customModal">
                <ModalHeader><h4>Add New Game</h4></ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>
                                        Name
                                    </Label>
                                    <Input
                                        // name="name"
                                        id="gameName"
                                        placeholder="Game Name"
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Studio Name</Label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        required
                                        value={selectedStudio}
                                        isLoading={false}
                                        isSearchable={true}
                                        name="color"
                                        options={studioNames}
                                        onChange={(selectedOption) => {
                                            setSelectedStudio(selectedOption);
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                            <Label>Bundle Id</Label>
                            <Input
                                id="gameKey"
                                name="gameKey"
                                placeholder="Bundle Id"
                                value={key}
                                onChange={(e) => setKey(e.target.value)}
                            />
                        </FormGroup>
                        <div className="text-center">
                            <Button color="primary" onClick={addNewGame}>
                                {loading === "adding" ? (
                                    <div className="spinner-border spinner-border-sm" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                ) : (
                                    <span className="fs-6">Save</span>
                                )}
                            </Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
            <Modal isOpen={editModal} toggle={toggleEditModal} className="customModal">
                <ModalHeader><h4>Edit Game</h4></ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>
                                        Name
                                    </Label>
                                    <Input
                                        // name="name"
                                        id="gameName"
                                        placeholder="Game Name"
                                        type="text"
                                        value={editGameObject.name}
                                        onChange={(e) => setEditGameObject({...editGameObject, name: e.target.value})}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Studio Name</Label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        required
                                        value={editGameObject.studioName}
                                        isLoading={false}
                                        isSearchable={true}
                                        name="color"
                                        options={studioNames}
                                        onChange={(selectedOption) => {
                                            setEditGameObject({...editGameObject, studioName: selectedOption})
                                            // setSelectedStudio(selectedOption);
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                            <Label>Bundle Id</Label>
                            <Input
                                id="gameKey"
                                name="gameKey"
                                placeholder="Bundle Id"
                                value={editGameObject.key}
                                onChange={(e) => setEditGameObject({...editGameObject, key: e.target.value})}
                            />
                        </FormGroup>
                        <div className="text-center">
                            <Button color="primary" onClick={saveEdit}>
                                {loading === "editing" ? (
                                    <div className="spinner-border spinner-border-sm" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                ) : (
                                    <span className="fs-6">Save</span>
                                )}
                            </Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default AddGame;