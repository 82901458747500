import React, {useEffect, useState} from 'react';
import Header from "../../components/Headers/Header";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from "reactstrap";
import {db} from "../../firebase";
import {addDoc, collection, getDocs, doc, deleteDoc} from "firebase/firestore";
import {toast} from "react-toastify";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import axios from "axios";

const AddStudio = () => {
    const [name, setName] = useState("");
    const [key, setKey] = useState("");
    const [studioNames, setStudioNames] = useState([]);
    const [studios, setStudios] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState("loadingTable");

    useEffect(() => {
        loadStudios().then(names => {
            console.log(names);
            setStudios(names);
            setLoading("ready");
        })
    }, []);
    useEffect(()=> {
        const names = studios.map(_studio => _studio.name);
        setStudioNames(names);
    },[studios])
    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            width: "20%"
        },
        {
            name: 'Actions',
            cell: (row) => (
                <>
                    <Button size="sm" color="danger" onClick={() => deleteStudio(row)}>
                        <i className="fas fa-trash"/>
                    </Button>
                </>
            ),
            center: true,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const loadStudios = async () => {
        const studiosCollection = collection(db, 'reporting_studios');
        const studiosSnapshot = await getDocs(studiosCollection);
        return studiosSnapshot.docs.map(doc => {
            return {
                ...doc.data(), id: doc.id
            }
        });
    }
    const deleteStudio = async (item) => {
        console.log(item);
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result)=> {
            try{
                if (result.isConfirmed){
                    Swal.fire({
                        title: 'Deleting Studio...',
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                    await deleteDoc(doc(db,"reporting_studios",item.id));
                    const reqBody = {
                        studioName : item.name
                    }
                    // https://us-central1-scary-teacher-integrity.cloudfunctions.net/removeStudio
                    const response = await axios.post("https://us-central1-scary-teacher-integrity.cloudfunctions.net/removeStudio", reqBody)
                    console.log(response);
                    const _studios = studios.filter((_studio) => _studio.id !== item.id);
                    setStudios(_studios);
                    Swal.close();
                }
            }catch (e) {
                console.log(e)
            }
        })
    }
    const addStudio = async () => {
        console.log(name, key);
        setLoading("pending");
        try {
            if(studioNames.includes(name)){
                toast.error('Studio Already Added', {
                    position: "top-right",
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                });
                setLoading(null);
                return;
            }
            const docRef = await addDoc(collection(db, 'reporting_studios'), {
                key: key,
                name: name,
            });
            console.log("Document written with ID: ", docRef.id);
            toast.success('Studio Added', {
                position: "top-right",
                autoClose: 2000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
            });
            setName("");
            setKey("");
            setLoading(null);
            let _studios = studios;
            _studios = [..._studios, {key: key, name: name, id :docRef.id}];
            setStudios(_studios);
            toggleModal();
        } catch (e) {
            console.log(e);
            setLoading(null);
        }
    }
    const toggleModal = () => {
        setName("");
        setKey("");
        setOpenModal(!openModal);
    }

    return (
        <div>
            <Header/>
            <Container className="mt--9" fluid>
                <Row className="mb-4">
                    <Col style={{paddingLeft: "15%", paddingRight: "15%"}}>
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h2 className="mb-0">Studios</h2>
                                    <Button color="primary" onClick={toggleModal}>Add Studio</Button>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <DataTable
                                    columns={columns}
                                    data={studios}
                                    progressPending={loading === "loadingTable"}
                                    pagination
                                    responsive
                                    persistTableHead
                                    highlightOnHover
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal isOpen={openModal} toggle={toggleModal} className="customModal">
                <ModalHeader>
                    <h4>Add New Studio</h4>
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>
                                        Name
                                    </Label>
                                    <Input
                                        id="studioName"
                                        placeholder="Studio Name"
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            {/*<Col md={6}>*/}
                            {/*</Col>*/}
                        </Row>
                        <FormGroup>
                            <Label>Studio Key</Label>
                            <div>
                            <textarea
                                className="form-control"
                                id="studioKey"
                                name="studioKey"
                                rows="12"
                                placeholder="Studio JSON Key"
                                value={key}
                                onChange={(e) => setKey(e.target.value)}
                            />
                            </div>
                        </FormGroup>
                        <div className="text-center">
                            <Button color="primary" onClick={addStudio}>
                                {loading === "pending" ? (
                                    <div className="spinner-border spinner-border-sm" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                ) : (
                                    <span className="fs-6">Save</span>
                                )}
                            </Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default AddStudio;