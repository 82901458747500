import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import Header from "components/Headers/Header.js";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import axios from "axios";
import { toast } from "react-toastify";
import { chartOptions, parseOptions } from "variables/charts.js";
import { Line } from "react-chartjs-2";
import Chart from "chart.js";
import moment from "moment-timezone";
let flag = false;

const options = {
  scales: {
    xAxes: [
      {
        // gridLines: {
        // display: false
        // }
      },
    ],
    yAxes: [
      {
        // gridLines: {
        //     display: false
        //     // color: colors.gray[900],
        //     // zeroLineColor: colors.gray[900],
        // },
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const Compare = (props) => {
  const [startDate, setStartDate] = useState(
    moment().tz("America/Los_Angeles").subtract(7, "days") // 7 days ago
  );
  const [endDate, setEndDate] = useState(
    moment().tz("America/Los_Angeles").endOf("day")
  );
  const [games, setGames] = useState([]);
  const [versions, setVersions] = useState([]);
  const [gamesNames, setGamesNames] = useState([]);
  const [selectedGame, setSelectedGame] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState([]);
  const [mainTableData, setMainTableData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(null);
  const [tableLoading, setTableLoading] = useState(null);
  const [gamesList, setGamesList] = useState([]);
  const [gameName, setGameName] = useState([]);
  const [graphLabels, setGraphLabels] = useState([]);
  const [gameData, setGameData] = useState([]);
  const [summary, setSummary] = useState([]);

  const [summaryAvg, setSummaryAvg] = useState({
    avgAnrRate: 0,
    avgCrashRate: 0,
    avgUserPerceivedCrashRate: 0,
    avgUserPerceivedAnrRate: 0,
  });
  useEffect(() => {
    console.log("Start date:", startDate.format());
    console.log("End date:", endDate.format());
  }, [startDate, endDate]);

  const [preDefinedDates, setPreDefinedDates] = useState([
    {
      label: "Last 7 Days",
      value: "7",
    },
    {
      label: "Last 15 Days",
      value: "15",
    },
    {
      label: "Last 30 Days",
      value: "30",
    },
    {
      label: "custom",
      value: "custom",
    },
  ]);
  const [selectedPreDefinedDate, setSelectedPreDefinedDate] = useState(
    preDefinedDates[0]
  );

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  useEffect(() => {
    setTableLoading(null);
  }, [selectedGame]);

  useEffect(() => {
    if (selectedGame.length > 0) {
      let temp = [];

      selectedGame.forEach((game, index) => {
        if (selectedGame.length == 1) {
          for (let i = 0; i < 2; ++i) {
            temp.push({
              labels: graphLabels[index],
              datasets: [
                {
                  label: "ANR Rate",
                  data: tableData[i]?.map((_row) =>
                    (_row.anrRate * 100).toFixed(2)
                  ),
                  borderColor: "#8b10e8",
                  fill: false,
                  pointBackgroundColor: "#8b10e8",
                  pointBorderColor: "#8b10e8",
                  pointRadius: 4,
                },
                {
                  label: "Crash Rate",
                  data: tableData[i]?.map((_row) =>
                    (_row.crashRate * 100).toFixed(2)
                  ),
                  borderColor: "#ff4704",
                  fill: false,
                  pointBackgroundColor: "#ff4704",
                  pointBorderColor: "#ff4704",
                  pointRadius: 4,
                },
              ],
            });
          }
        } else {
          temp.push({
            labels: graphLabels[index],
            datasets: [
              {
                label: "ANR Rate",
                data: tableData[index]?.map((_row) =>
                  (_row.anrRate * 100).toFixed(2)
                ),
                borderColor: "#8b10e8",
                fill: false,
                pointBackgroundColor: "#8b10e8",
                pointBorderColor: "#8b10e8",
                pointRadius: 4,
              },
              {
                label: "Crash Rate",
                data: tableData[index]?.map((_row) =>
                  (_row.crashRate * 100).toFixed(2)
                ),
                borderColor: "#ff4704",
                fill: false,
                pointBackgroundColor: "#ff4704",
                pointBorderColor: "#ff4704",
                pointRadius: 4,
              },
            ],
          });
        }
      });
      setGameData(temp);
    }
  }, [tableData, graphLabels, selectedGame]);

  const updateChartData = (index, selectedValue) => {
    let temp = [...tableData];
    let fullData = [...mainTableData]; // Create a new copy of the array
    if (selectedGame.length == 1) {
      temp.push([...tableData]);
    }

    if (selectedValue.value === "all") {
      temp[index] = mainTableData[index];
    } else {
      temp[index] = fullData[index].filter(
        (item) => item.versionCode === selectedValue.value
      );
    }
    setTableData(temp);
  };

  const updateSingleGraph = (index, selectedValue) => {
    let temp = [...tableData];
    let fullData = [...mainTableData]; // Create a new copy of the array
    if (selectedValue.value === "all") {
      temp[index] = fullData[index];
    } else {
      temp[index] = fullData[index].filter(
        (item) => item.versionCode === selectedValue.value
      );
    }
    setTableData(temp);
  };

  useEffect(() => {}, [gameData, tableData, graphLabels]);

  useEffect(() => {
    loadGames().then((result) => {
      let gameNames = [];
      let gamesBO = [];
      result.forEach((_item) => {
        gameNames.push(_item.name);
        gamesBO[_item.name] = _item;
      });
      const options = gameNames.map((name) => ({ value: name, label: name }));
      setGamesList(gamesBO);
      setGamesNames(options);
      setGames(result);
    });
  }, []);

  useEffect(() => {
    let temp = [];

    tableData.forEach((item, index) => {
      let sumAnrs = 0;
      let avgAnrs = 0;
      let sumCrashes = 0;
      let avgCrashes = 0;

      item.forEach((subItem) => {
        sumAnrs += subItem.anrRate * 100;
        sumCrashes += subItem.crashRate * 100;
      });
      avgAnrs = sumAnrs / item.length;
      avgCrashes = sumCrashes / item.length;

      if (selectedGame.length == 1) {
        temp.push({
          gameName: selectedGame[0].value,
          anrRate: avgAnrs,
          crashRate: avgCrashes,
        });
      } else {
        temp.push({
          gameName: selectedGame[index].value,
          anrRate: avgAnrs,
          crashRate: avgCrashes,
        });
      }
    });
    setSummary(temp);
  }, [tableData]);

  useEffect(() => {
    if (selectedPreDefinedDate !== "custom") {
      console.log("predefined date changed");
      const laTimezone = "America/Los_Angeles";

      if (selectedPreDefinedDate.value === "7") {
        setStartDate(moment().tz(laTimezone).subtract(7, "days"));
      } else if (selectedPreDefinedDate.value === "15") {
        setStartDate(moment().tz(laTimezone).subtract(15, "days"));
      } else if (selectedPreDefinedDate.value === "30") {
        setStartDate(moment().tz(laTimezone).subtract(30, "days"));
      }
      if (flag) {
        setEndDate(endDate.subtract(1, "days"));
        console.log("end official date is ", endDate);
        applyFilters();
      }
    }
  }, [selectedPreDefinedDate, flag]);

  const clearFilters = () => {
    setSelectedGame([]);
    setSelectedVersion([]);
    setTableData([]);
    setSelectedPreDefinedDate(preDefinedDates[0]);
  };

  const applyFilters = async (e) => {
    if (e) e.preventDefault();
    try {
      setLoading("pending");
      setTableData([]);
      setTableLoading("pending");
      if (startDate === null || endDate === null) {
        toast.error("Invalid Date", {
          position: "top-right",
          autoClose: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
        setLoading(null);
        setTableLoading(null);
        return;
      }
      console.log("start date:", startDate.format("DD-MM-YYYY"));
      console.log("end date:", endDate.format("DD-MM-YYYY"));

      const reqBodyArray = [];
      selectedGame.forEach((game) => {
        const reqBody = {
          startDate: startDate.format("DD-MM-YYYY"),
          endDate: endDate.format("DD-MM-YYYY"),
          name: gamesList[game.value].key,
          studioId: gamesList[game.value].studioName,
          version: "1.0",
        };
        reqBodyArray.push(reqBody);
      });

      let tempTableData = [];
      let tempGraphLabels = [];
      let tempMainTableData = [];
      let tempVersions = [];
      for (let i = 0; i < reqBodyArray.length; i++) {
        const response = await axios.post(
          "https://us-central1-scary-teacher-integrity.cloudfunctions.net/crashRateCheck",
          reqBodyArray[i]
        );
        const data = response.data;
        const anrData = data.data.anrData;
        const crashRateData = data.data.CrashRateData;
        const combinedData = [];
        if (anrData.rows && crashRateData.rows) {
          anrData.rows.forEach((anrRow) => {
            const combinedRow = {
              startTime: anrRow.startTime,
              versionCode: anrRow.dimensions[0].stringValue,
              anrRate: parseFloat(
                anrRow.metrics.find((metric) => metric.metric === "anrRate")
                  .decimalValue.value
              ),
              anrRate7dUserWeighted: parseFloat(
                anrRow.metrics.find(
                  (metric) => metric.metric === "anrRate7dUserWeighted"
                ).decimalValue.value
              ),
            };

            const crashRow = crashRateData.rows.find((crashRow) => {
              const crashRowDate = new Date(
                crashRow.startTime.year,
                crashRow.startTime.month - 1,
                crashRow.startTime.day
              );
              const anrRowDate = new Date(
                anrRow.startTime.year,
                anrRow.startTime.month - 1,
                anrRow.startTime.day
              );
              return (
                crashRowDate.getTime() === anrRowDate.getTime() &&
                crashRow.dimensions[0].stringValue === combinedRow.versionCode
              );
            });

            if (crashRow) {
              combinedRow.crashRate7dUserWeighted = parseFloat(
                crashRow.metrics.find(
                  (metric) => metric.metric === "crashRate7dUserWeighted"
                ).decimalValue.value
              );
              combinedRow.crashRate = parseFloat(
                crashRow.metrics.find((metric) => metric.metric === "crashRate")
                  .decimalValue.value
              );
              combinedData.push(combinedRow);
            }
          });
          const uniqueVersionCodes = combinedData
            .map((item) => item.versionCode)
            .filter((value, index, self) => self.indexOf(value) === index);
          const versionOptions = uniqueVersionCodes.map((version) => ({
            value: version,
            label: version,
          }));
          tempVersions.push([
            { value: "all", label: "All" },
            ...versionOptions,
          ]);

          if (selectedGame.length == 1) {
            tempVersions.push([
              { value: "all", label: "All" },
              ...versionOptions,
            ]);
          }
          const sortedData = combinedData.sort((a, b) => {
            const dateA = new Date(
              a.startTime.year,
              a.startTime.month - 1,
              a.startTime.day
            );
            const dateB = new Date(
              b.startTime.year,
              b.startTime.month - 1,
              b.startTime.day
            );
            return dateB - dateA;
          });

          tempTableData.push(sortedData);

          tempGraphLabels.push(
            sortedData.map((row) => {
              return moment([
                row.startTime.year,
                row.startTime.month - 1,
                row.startTime.day,
              ]).format("DD-MM-YYYY");
            })
          );

          tempMainTableData.push(sortedData);

          if (selectedGame.length == 1) {
            tempTableData.push(sortedData);
          }
          setGameName(selectedGame[i].value);
        } else {
          setLoading(null);
          setTableLoading(null);
        }
      }

      setTableData(tempTableData);
      setGraphLabels(tempGraphLabels);
      setMainTableData(tempTableData);

      setVersions(tempVersions);

      setLoading("ready");
      setTableLoading("ready");
    } catch (e) {
      flag = true;
      setLoading(null);
      setTableLoading(null);
    }
  };
  const loadGames = async () => {
    const gamesCollection = collection(db, "reporting_games");
    const gamesSnapshot = await getDocs(gamesCollection);
    return gamesSnapshot.docs.map((doc) => doc.data());
  };
  const renderDay = (props, currentDate, selectedDate, startDate, _endDate) => {
    // const endDate = moment(_endDate).subtract(24, "hours");

    let classes = props.className;
    if (startDate && _endDate && startDate._d + "" === currentDate._d + "") {
      classes += " start-date";
    } else if (
      startDate &&
      endDate &&
      new Date(startDate._d + "") < new Date(currentDate._d + "") &&
      new Date(_endDate._d + "") > new Date(currentDate._d + "")
    ) {
      classes += " middle-date";
    } else if (_endDate && _endDate._d + "" === currentDate._d + "") {
      classes += " end-date";
    }
    return (
      <td {...props} className={classes}>
        {currentDate.date()}
      </td>
    );
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9" fluid>
        <Row className="mb-4">
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Form onSubmit={applyFilters}>
                  <h2 className="mb-0">Filters</h2>
                  <Row>
                    <Col md={3}>
                      <h5 className="text-muted">Game</h5>
                      <Select
                        className="py-1"
                        classNamePrefix="select"
                        required
                        isMulti={true}
                        value={selectedGame}
                        isLoading={false}
                        isSearchable={true}
                        name="color"
                        options={gamesNames}
                        onChange={(selectedOption) => {
                          setSelectedGame(selectedOption);
                        }}
                      />
                    </Col>

                    <Col md={3}>
                      <h5 className="text-muted">Dates</h5>
                      <Select
                        className="py-1"
                        classNamePrefix="select"
                        required
                        value={selectedPreDefinedDate}
                        isLoading={false}
                        name="dateSelect"
                        options={preDefinedDates}
                        onChange={(selectedOption) => {
                          setSelectedPreDefinedDate(selectedOption);
                        }}
                      />
                    </Col>

                    {selectedPreDefinedDate &&
                    selectedPreDefinedDate?.value === "custom" ? (
                      <>
                        <Col md={3}>
                          <h5 className="text-muted">From</h5>
                          <FormGroup>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-calendar-grid-58" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <ReactDatetime
                                inputProps={{
                                  placeholder: "DD/MM/YYYY",
                                }}
                                timeFormat={false}
                                renderDay={(props, currentDate, selectedDate) =>
                                  renderDay(
                                    props,
                                    currentDate,
                                    selectedDate,
                                    startDate,
                                    endDate
                                  )
                                }
                                onChange={(e) => {
                                  setStartDate(e);
                                  if (endDate && e.isAfter(endDate)) {
                                    setEndDate(null); // Reset end date if it is before start date
                                  }
                                }}
                                isValidDate={(current) => {
                                  return (
                                    current.isBefore(endDate) ||
                                    current.isSame(endDate)
                                  );
                                }}
                                value={startDate}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col md={3}>
                          <h5 className="text-muted">To</h5>
                          <FormGroup>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-calendar-grid-58" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <ReactDatetime
                                inputProps={{
                                  placeholder: "DD/MM/YYYY",
                                }}
                                timeFormat={false}
                                renderDay={(props, currentDate, selectedDate) =>
                                  renderDay(
                                    props,
                                    currentDate,
                                    selectedDate,
                                    startDate,
                                    endDate
                                  )
                                }
                                onChange={(e) => setEndDate(e)}
                                isValidDate={(current) => {
                                  return (
                                      current.isAfter(startDate, "day") &&
                                      (current.isBefore(moment()) ||
                                          current.isSame(moment(), "day"))
                                  );
                                }}
                                value={endDate}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </>
                    ) : null}
                  </Row>
                  <Row className="align-items-end">
                    <Col className="text-right">
                      <Button color="danger" onClick={clearFilters}>
                        Clear
                      </Button>
                      <Button color="primary" type="submit">
                        {loading === "pending" ? (
                          <div
                            className="spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <span className="fs-6">Apply</span>
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardHeader>
              <CardBody>
                {tableData.length > 0 && tableLoading === "ready" && (
                  <div className="mb-4 row ">
                    {selectedGame.length > 1
                      ? selectedGame.map((game, index) => {
                          return (
                            <>
                              <div className="col-6 text-center">
                                <h3 className="text-muted">{game.label}</h3>
                                <Col md={3} className="mb-4">
                                  <h5 className="text-muted">Version</h5>
                                  <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    value={selectedVersion[index]}
                                    isLoading={false}
                                    isSearchable={true}
                                    name="color"
                                    options={versions[index]}
                                    onChange={(selectedOption) => {
                                      updateChartData(index, selectedOption);
                                    }}
                                  />
                                </Col>

                                <Line
                                  options={options}
                                  data={gameData[index]}
                                />
                              </div>
                            </>
                          );
                        })
                      : gameData.map((game, index) => {
                          return (
                            <>
                              <div className="col-6 text-center" key={index}>
                                <h3 className="text-muted">
                                  {selectedGame[0]?.label}
                                </h3>
                                <Col md={3} className="mb-4">
                                  <h5 className="text-muted">Version</h5>
                                  <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    value={selectedVersion[index]}
                                    isLoading={false}
                                    isSearchable={true}
                                    name="color"
                                    options={versions[index]}
                                    onChange={(selectedOption) => {
                                      updateSingleGraph(index, selectedOption);
                                    }}
                                  />
                                </Col>

                                <Line
                                  options={options}
                                  data={gameData[index]}
                                />
                              </div>
                            </>
                          );
                        })}
                  </div>
                )}

                {tableLoading === "ready" && (
                  <div className="row">
                    <h3>Summary</h3>
                    <table className="table">
                      <thead>
                        <td>Game Name</td>
                        <td>Avg ANRs</td>
                        <td>Avg Crash Rate</td>
                      </thead>
                      <tbody>
                        {summary.map((game, index) => {
                          return (
                            <tr key={index}>
                              <td>{game?.gameName}</td>
                              <td>{game?.anrRate.toFixed(2)} %</td>
                              <td>{game?.crashRate.toFixed(2)} %</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Compare;
