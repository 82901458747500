import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {db} from "./firebase";
import {doc, getDoc} from "firebase/firestore";
import "./index.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import {Spinner} from "reactstrap";

function App() {
    // const [user, setUser] = useState({});
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [signedInUser, setSignedInUser] = useState(getAuth().currentUser);

    useEffect(() => {
        onAuthStateChanged(getAuth(), async (user) => {
            if (user && !isLoggedIn) {
                let _user = user;
                console.log(user)
                const adminsRef = doc(db, "admins", user.uid);
                const adminSnap = await getDoc(adminsRef);
                if (adminSnap.exists()) {
                    _user = {..._user, ...adminSnap.data()};
                }
                console.log(_user);
                setSignedInUser(_user);
                setIsLoggedIn(true);
            } else {
                setSignedInUser(null);
                setIsLoggedIn(false);
            }
            setIsLoading(false);
        });
    }, []);

    return (
        <BrowserRouter>
            <>
                {
                    isLoading ?
                        <div className="text-center mt-7">
                            <Spinner
                                color={'primary'}
                                size={''}
                            >
                                Loading...
                            </Spinner>
                        </div> :
                        <Switch>
                            <Route path="/admin" render={props => {
                                if (signedInUser) return <AdminLayout {...props} signedInUser={signedInUser}/>;
                                return <Redirect to="/auth/login"/>
                            }}/>
                            <Route path="/auth" render={props => {
                                if (!signedInUser) return <AuthLayout {...props} signedInUser={signedInUser}/>;
                                return <Redirect to="/admin/dashboard"/>
                            }}/>
                            <Redirect from="/" to="/admin/dashboard"/>
                        </Switch>
                }
            </>
            <ToastContainer />
        </BrowserRouter>
    );
}

ReactDOM.render(<App />, document.getElementById("root"));
