import React from "react";
import {useLocation, Route, Switch, Redirect} from "react-router-dom";
import {Container} from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";

const Admin = (props) => {
    const mainContent = React.useRef(null);
    const location = useLocation();

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContent.current.scrollTop = 0;
    }, [location]);

    const filteredRoutes = routes.filter(route => {
        if (props.signedInUser.role === "superAdmin") {
            return route.display;
        } else if (props.signedInUser.role === "admin") {
            return route.role === "admin" && route.display;
        } else {
            return false;
        }
    });

    const getRoutes = () => {
        return filteredRoutes.map((prop, key) => {
            return (
                <Route
                    path={prop.layout + prop.path}
                    render={renderProps => <prop.component {...renderProps} {...props} />}
                    key={key}
                />
            );
        });
    };

    const getBrandText = (path) => {
        for (let i = 0; i < routes.length; i++) {
            if (props.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
                return routes[i].name;
            }
        }
        return "Brand";
    };

    return (
        <>
            <Sidebar
                {...props}
                routes={filteredRoutes}
                logo={{
                    innerLink: "/admin/index",
                    imgSrc: require("../assets/img/brand/logo1.png"),
                    imgAlt: "..."
                }}
            />
            <div className="main-content" ref={mainContent}>
                <AdminNavbar {...props} brandText={getBrandText(props.location.pathname)} />
                <Switch>
                    {getRoutes()}
                    <Redirect from="*" to="/admin/index" />
                </Switch>
                <Container fluid>
                    {/*<AdminFooter />*/}
                </Container>
            </div>
        </>
    );
};

export default Admin;
